import request from '@/utils/request'

export function getMemberOrder(parameter) {
  return request({
    url: '/member/order',
    method: 'get',
    params: parameter
  })
}

export function queryCashDetail(parameter) {
  return request({
    url: '/member/order/exchange/detail/'+parameter.id,
    method: 'post',
    data: parameter
  })
}

export function queryMemberOrders(parameter) {
  // debugger;
  return request({
    url: '/member/order',
    method: 'post',
    data: parameter
  })
}

export function queryMemberStatistics(parameter) {
  // debugger;
  return request({
    url: '/member/order/finance/order',
    method: 'post',
    data: parameter
  })
}

export function putMemberOrder(parameter) {
  return request({
    url: '/member/order',
    method: 'put',
    data: parameter
  })
}

export function addMemberOrder(parameter) {
  return request({
    url: '/member/order/add',
    method: 'put',
    data: parameter
  })
}

export function addPackageOrder(parameter) {
  // debugger;
  return request({
    url: '/member/order/add/package',
    method: 'put',
    data: parameter
  })
}

export function cancelMemberOrder(parameter) {
  return request({
    url: '/member/order/cancel',
    method: 'post',
    data: parameter
  })
}

export function deliverMemberOrder(parameter) {
  return request({
    url: '/member/order/deliver',
    method: 'post',
    data: parameter
  })
}

export function drawCommonOrder(parameter) {
  return request({
    url: '/member/order/draw',
    method: 'put',
    data: parameter
  })
}

export function getMemberOrderAddress(parameter) {
  return request({
    url: '/member/order/address',
    method: 'get',
    params: parameter
  })
}

export function putMemberOrderAddress(parameter) {
  return request({
    url: '/member/order/address',
    method: 'put',
    data: parameter
  })
}

export function getMemberOrderProductions(parameter) {
  return request({
    url: '/member/order/production',
    method: 'post',
    data: parameter
  })
}

export function queryAreaOrder(parameter) {
  return request({
    url: '/performance/area/order/page',
    method: 'post',
    data: parameter
  })
}

export function getOrderData(parameter) {
  return request({
    url: '/member/order/data',
    method: 'get',
    params: parameter
  })
}

export function payMemberOrder(parameter) {
  return request({
    url: '/member/order/pay',
    method: 'POST',
    data: parameter
  })
}

export function payDirectMemberOrder(parameter) {
  return request({
    url: '/member/order/direct/pay/confirm',
    method: 'POST',
    data: parameter
  })
}

export function getMemberBank(parameter) {
  return request({
    url: '/member/order/bank',
    method: 'get',
    params: parameter
  })
}

export function getMemberCompute(parameter) {
  return request({
    url: '/member/order/compute',
    method: 'get',
    params: parameter
  })
}

export function deleteMemberOrder(parameter) {
  return request({
    url: '/member/order',
    method: 'delete',
    params: parameter
  })
}

export function getOrderCorrect(parameter) {
  return request({
    url: '/member/order/correct/detail/' + parameter.id,
    method: 'get',
  })
}

export function setOrderCorrect(parameter) {
  return request({
    url: '/member/order/correct',
    method: 'post',
    data: parameter
  })
}

export function queryMerchantOrder(parameter) {
  return request({
    url: '/member/order/v2',
    method: 'post',
    data: parameter
  })
}

export function queryDirectOrder(parameter) {
  return request({
    url: '/member/order/direct/pay/page',
    method: 'post',
    data: parameter
  })
}

export function queryNoAuditOrder(parameter) {
  return request({
    url: '/member/order/noaudit/page',
    method: 'post',
    data: parameter
  })
}

export function queryRewardList(parameter) {
  return request({
    url: '/member/order/query/reward',
    method: 'post',
    data: parameter
  })
}

export function getMerchantOrderData(parameter) {
  return request({
    url: '/member/order/data/v2',
    method: 'get',
    params: parameter
  })
}

export function queryMerchantWaitOrder(parameter) {
  return request({
    url: '/member/order/merchant/wait/page',
    method: 'post',
    data: parameter
  })
}

export function updateMemberOrder(parameter) {
  return request({
    url: '/member/order/update/alreadypay',
    method: 'post',
    data: parameter
  })
}

export function updateMerchantQuantity(parameter) {
  return request({
    url: '/wealth/quota/update/v2',
    method: 'post',
    data: parameter
  })
}

export function queryMerchantCash(parameter) {
  return request({
    url: '/merchant/cash/page',
    method: 'GET',
    params: parameter
  })
}

// export function getAuditOrderData(parameter) {
//   return request({
//     url: '/member/order/audit/statistics',
//     method: 'POST',
//     data: parameter
//   })
// }

export function getAuditOrderDataV2(parameter) {
  return request({
    url: '/member/order/audit/statistics/v2',
    method: 'POST',
    data: parameter
  })
}

export function removeAuditOrder(parameter) {
  return request({
    url: '/member/order/audit/del/' + parameter.id,
    method: 'delete',
  })
}

export function putAuditOrder(parameter) {
  return request({
    url: '/member/order/audit/save',
    method: 'POST',
    data: parameter
  })
}

export function putOrderExtra(parameter) {
  return request({
    url: '/member/order/audit/extra/save',
    method: 'POST',
    data: parameter
  })
}

export function getAuditRecord(parameter) {
  return request({
    url: '/member/order/audit/record/' + parameter.id,
    method: 'GET',
  })
}

export function getMobileMember(parameter) {
  return request({
    url: '/member/phone',
    method: 'GET',
    params: parameter
  })
}

export function getMerchantList(parameter) {
  return request({
    url: '/member/merchant',
    method: 'GET',
    params: parameter
  })
}

export function querySpecialOrders(parameter) {
  return request({
    url: '/member/order/special',
    method: 'post',
    data: parameter
  })
}

export function getSpecialTotal(parameter) {
  return request({
    url: '/member/order/special/total',
    method: 'post',
    data: parameter
  })
}

export function getQuotaOrderDetail(parameter) {
  return request({
    url: '/wealth/quota/order/detail',
    method: 'GET',
    params: parameter
  })
}

//查询某个商户的运营业绩(不含下级商户的业绩列表)
export function getPresidentDetail(parameter) {
  return request({
    url: '/performance/president/detail/'+parameter.id,
    method: 'GET',
    params: parameter
  })
}

export function getMobileMerchant(parameter) {
  return request({
    url: '/member/bind/query',
    method: 'GET',
    params: parameter
  })
}

export function putBindMerchant(parameter) {
  return request({
    url: '/member/bind/merchant',
    method: 'post',
    data: parameter
  })
}

export function putOrderProduction(parameter) {
  return request({
    url: '/member/order/update/production',
    method: 'post',
    data: parameter
  })
}

export function putExchangeProduction(parameter) {
  return request({
    url: '/member/order/update/production/exchange',
    method: 'post',
    data: parameter
  })
}

export function getDepositoryStatistics(parameter) {
  return request({
    url: '/member/order/depository/statistics',
    method: 'post',
    data: parameter
  })
}


export function putOrderDraw(parameter) {
  return request({
    url: '/member/order/draw',
    method: 'put',
    data: parameter
  })
}

export function queryDiscount(parameter) {
  return request({
    url: '/wealth/quota/month/record',
    method: 'get',
    params: parameter
  })
}

export function queryDiscountHeard(parameter) {
  return request({
    url: '/wealth/quota/month/record/data',
    method: 'get',
    params: parameter
  })
}

export function queryCash(parameter) {
  return request({
    url: '/member/record/cash',
    method: 'get',
    params: parameter
  })
}

export function queryCashHead(parameter) {
  return request({
    url: '/member/record/cash/data',
    method: 'get',
    params: parameter
  })
}

export function queryActivityOrder(parameter) {
  return request({
    url: '/sign/tool/activity/order/page/v2',
    method: 'post',
    data: parameter
  })
}

export function queryActivityOrder499(parameter) {
  return request({
    url: '/499/act/order/page',
    method: 'post',
    data: parameter
  })
}

export function queryExchangeOrder(parameter) {
  return request({
    url: '/member/order/exchange/list',
    method: 'post',
    data: parameter
  })
}

//录入兑换订单
export function addExchangeOrder(parameter) {
  return request({
    url: '/member/order/add/exchange',
    method: 'put',
    data: parameter
  })
}

export function deleteActivityOrder(parameter) {
  return request({
    url: '/sign/tool/activity/order/del/' + parameter.id,
    method: 'delete',
  })
}

export function deleteExchangeOrder(parameter) {
  return request({
    url: '/member/order/delete/exchange/' + parameter.id,
    method: 'delete',
  })
}

export function outActivityOrder(parameter) {
  return request({
    url: '/sign/tool/activity/order/out/' + parameter.id,
    method: 'post',
  })
}
//499活动出库
export function outActivityOrder499(parameter) {
  return request({
    url: '/499/act/order/out/' + parameter.id,
    method: 'post',
  })
}

//1586订单出库
export function outMemberOrder1586(parameter) {
  return request({
    url: '/member/order/deliver/',
    method: 'post',
  })
}

//出库删除1586
export function deleteOutMemberOrder1586(parameter) {
  return request({
    url: '/partner/1586/order/out/del/' + parameter.id,
    method: 'delete',
  })
}

//499选择时间
export function selectTimeActivity499(parameter) {
  return request({
    url: '/499/act/select/time',
    method: 'post',
    data: parameter
  })
}

//499核销
export function checkActivity499(parameter) {
  return request({
    url: '/499/act/order/check/'+ parameter.id,
    method: 'post',
    data: parameter
  })
}

//499退单
export function orderReturnActivity499(parameter) {
  return request({
    url: '/499/act/order/return',
    method: 'post',
    data: parameter
  })
}

export function outExchangeOrder(parameter) {
  return request({
    url: '/member/order/deliver/exchange',
    method: 'post',
    data: parameter
  })
}

export function drawExchangeOrder(parameter) {
  return request({
    url: '/member/order/draw/exchange',
    method: 'put',
    data: parameter
  })
}

export function putMemberOrderTransfer(parameter) {
  return request({
    url: '/member/order/transfer',
    method: 'post',
    data: parameter
  })
}

export function getOrderLedgerStatistics(parameter) {
  return request({
    url: '/member/order/ledger/statistics',
    method: 'post',
    data: parameter
  })
}

export function getOrderFinanceStatistics(parameter) {
  return request({
    url: '/member/order/finance/order/statistics',
    method: 'post',
    data: parameter
  })
}

export function getOrderLedgerAccount(parameter) {
  return request({
    url: '/member/order/ledger/balance/list',
    method: 'get',
    params: parameter
  })
}

export function putOrderLedger(parameter) {
  return request({
    url: '/member/order/ledger/add',
    method: 'post',
    data: parameter
  })
}

export function getMemberCash(parameter) {
  return request({
    url: '/integral/member/cash',
    method: 'get',
    params: parameter
  })
}
//退款
export function refund(parameter) {
  return request({
    url: '/member/order/quick/confirm/refund',
    method: 'post',
    data: parameter
  })
}

//新增商户
export function addMerchant(parameter) {
  return request({
    url: '/merchant/add/merchant',
    method: 'post',
    data: parameter
  })
}
//499核销单，订单详情
export function queryOrderDetail499(parameter) {
  return request({
    url: '/499/act/order/detail/'+parameter.id,
    method: 'get',
    data: parameter
  })
}

//合伙人订单-分页查询
export function queryPartnerOrder(parameter) {
  return request({
    url: '/partner/order/page',
    method: 'post',
    data: parameter
  })
}

//合伙人订单详情
export function queryPartnerOrderDetail(parameter) {
  return request({
    url: '/partner/order/detail/'+parameter.id,
    method: 'get',
    data: parameter
  })
}

//合伙人删除
export function deletePartnerOrder(parameter) {
  return request({
    url: '/member/order/audit/del/'+parameter.id,
    method: 'delete',
    params: parameter
  })
}

//1586会员订单-分页查询
export function queryMemberOrder1586(parameter) {
  return request({
    url: '/partner/1586/order/page',
    method: 'post',
    data: parameter
  })
}

//1586会员订单详情
export function queryMemberOrderDetail1586(parameter) {
  return request({
    url: '/partner/1586/order/detail/'+parameter.id,
    method: 'get',
    data: parameter
  })
}

//1586会员订单删除
export function deleteMemberOrder1586(parameter) {
  return request({
    url: '/member/order/audit/del/'+parameter.id,
    method: 'delete',
    params: parameter
  })
}



